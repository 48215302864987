@use "variable" as var;

.documentBody {
  padding: 20px;
  .documentArea {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 744.0945px;
    height: 1052.36225px;
    background-color: #666;
    .displayWrap {
      position: relative;
      width: 49%;
      &::before {
        content: "";
        display: block;
        width: 0;
        padding-top: calc(667 / 375 * 100%);
      }
      .display {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var.$white;
      }
    }
    .documentWrap {
      width: 49%;
      padding: 16px;
    }
  }
}

.mainBody {
  display: flex;
  align-items: center;
  background-image: url("/images/bg/default/bg_body01.jpg");
  background-size: 101% 101%;
  background-position: center;
  background-color: #666;
  .displayWrap {
    position: relative;
    margin: 0 auto;
    background-color: var.$white;
  }
}
